<template>
  <div class="cursor-pointer">
    <q-input
      :value="formatedValue"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      class="no-pointer-events"
    />

    <q-popup-proxy :cover="false" :offset="[0, 10]" max-width="320px">
      <q-date :value="dateValue" :range="range" @input="onInput" />
    </q-popup-proxy>
  </div>
</template>

<script>
import { date } from 'quasar';

export default {
  props: {
    value: {
      type: [Object],
    },
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    range: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateValue() {
      return this.value?.from === this.value?.to
        ? this.value?.from
        : this.value;
    },
    formatedValue() {
      if (!this.value) {
        return '';
      }

      if (this.value.from === this.value.to) {
        const fromDate = date.extractDate(this.value.from, 'YYYY/MM/DD');

        return fromDate.toLocaleString('en', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        });
      }

      const fromDate = date.extractDate(this.value.from, 'YYYY/MM/DD');
      const toDate = date.extractDate(this.value.to, 'YYYY/MM/DD');

      return [
        fromDate.toLocaleString('en', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }),

        toDate.toLocaleString('en', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }),
      ].join(' - ');
    },
  },
  methods: {
    onInput(value) {
      const range =
        typeof value === 'string' ? { from: value, to: value } : value;

      this.$emit('input', range);
    },
  },
};
</script>
