export const tableColumns = [
  {
    name: 'id',
    field: 'id',
    label: 'ID',
    align: 'left',
    required: true,
  },
  {
    name: 'uid',
    field: 'uid',
    label: 'UID',
    align: 'left',
  },
  {
    name: 'created-at',
    label: 'Created At',
    field: 'createdAt',
    align: 'left',
  },
  {
    name: 'updated-at',
    label: 'Updated At',
    field: 'UpdatedAt',
    align: 'left',
  },
  {
    name: 'agent',
    label: 'Agent',
    field: 'agent',
    align: 'left',
  },
  {
    name: 'author',
    label: 'Author',
    field: 'author',
    align: 'left',
  },
  {
    name: 'input-amount',
    label: 'Input amount',
    field: 'inputAmount',
  },
  {
    name: 'agent-amount',
    label: 'Agent amount',
    field: 'agentAmount',
  },
  {
    name: 'agent-fee',
    label: 'Agent fee',
    field: 'agentFee',
  },
  {
    name: 'comment',
    label: 'Comment',
    field: 'comment',
  },
  {
    name: 'status',
    label: 'Status',
    field: 'status',
  },
  {
    name: 'type',
    label: 'Type',
    field: 'type',
  },
  {
    name: 'reason',
    label: 'Reason',
    field: 'reason',
    align: 'left',
  },
  {
    name: 'merchantTransactionId',
    label: 'Merchant Transaction Id',
    field: 'merchantTransactionId',
  },
  {
    name: 'userId',
    label: 'User Id',
    field: 'userId',
  },
];
