<template>
  <DViewCard :loading="balanceChangesHistory.loading">
    <q-card class="history-of-balance-changes">
      <q-card-section>
        <h1 class="text-h5 q-mt-none q-mb-md text-weight-medium">
          History of balance changes
        </h1>

        <q-card bordered flat class="q-mb-md">
          <q-card-section>
            <div class="row q-col-gutter-md">
              <div
                :class="{
                  'col-12 col-md-4': accessFilterAgent,
                  'col-12 col-sm-6 col-lg-3': !accessFilterAgent,
                }"
              >
                <DDatePicker
                  ref="createdAt"
                  v-model="balanceChangesHistory.filter.createdAt"
                  dense
                  outlined
                  label="Created At"
                />
              </div>

              <div
                :class="{
                  'col-12 col-md-8': accessFilterAgent,
                  'col-12 col-sm-6 col-lg-3': !accessFilterAgent,
                }"
              >
                <DAutocomplete
                  ref="reason"
                  v-model="balanceChangesHistory.filter.reason"
                  :options="balanceChangeReasonDictionary"
                  :class="{ 'q-mt-md': isXsScreen }"
                  :use-input="false"
                  dense
                  emit-value
                  map-options
                  outlined
                  label="Reason"
                />
              </div>

              <div v-if="accessFilterAgent" class="col-12 col-sm-6 col-lg-3">
                <DAutocomplete
                  ref="agent"
                  v-model="balanceChangesHistory.filter.agentId"
                  :loading="agents.loading"
                  :options="agents.list"
                  :class="{ 'q-mt-md': isXsScreen }"
                  dense
                  emit-value
                  map-options
                  outlined
                  label="Agent"
                />
              </div>

              <div v-if="accessFilterAgent" class="col-12 col-sm-6 col-lg-3">
                <DAutocomplete
                  ref="currency"
                  v-model="balanceChangesHistory.filter.agentCurrency"
                  :loading="currency.loading"
                  :options="currency.list"
                  :class="{ 'q-mt-md': isXsScreen }"
                  dense
                  emit-value
                  map-options
                  outlined
                  label="Currency"
                />
              </div>

              <div class="col-12 col-sm-6 col-lg-3">
                <DAutocomplete
                  ref="operationType"
                  v-model="balanceChangesHistory.filter.operationType"
                  :options="balanceChangeOperationTypeDictionary"
                  :rules="[fieldRules.operationType]"
                  :class="{ 'q-mt-md': isXsScreen }"
                  dense
                  emit-value
                  map-options
                  outlined
                  label="Operation type"
                />
              </div>

              <div class="col-12 col-sm-6 col-lg-3">
                <q-input
                  ref="merchantTransactionId"
                  v-model="balanceChangesHistory.filter.merchantTransactionId"
                  :rules="[fieldRules.merchantTransactionId]"
                  dense
                  outlined
                  clearable
                  label="Merchant Transaction Id"
                />
              </div>
            </div>

            <q-btn
              unelevated
              color="primary"
              class="q-px-sm flex q-mt-xs q-ml-auto"
              @click="fetchBalanceChanges"
            >
              Apply
            </q-btn>
          </q-card-section>
        </q-card>

        <q-table
          :data="balanceChangesHistory.list"
          :columns="tableColumns"
          :rowsPerPageOptions="[20, 40, 80]"
          :grid="$q.screen.lt.lg"
          flat
          bordered
          :class="[
            'history-of-balance-changes__table',
            {
              'history-of-balance-changes__table_with_agent-filter':
                accessFilterAgent,
            },
          ]"
        >
          <template #item="props">
            <div
              class="q-pa-xs col-xs-12 col-sm-6 col-md-4 col-lg-3 grid-style-transition"
            >
              <q-card bordered flat>
                <q-list>
                  <q-item v-for="col in props.cols" :key="col.name">
                    <q-item-section>
                      <q-item-label>{{ col.label }}</q-item-label>

                      <q-item-label caption>
                        <template v-if="col.name === 'created-at'">
                          {{ formatDate(props.row.createdAt) }}
                        </template>

                        <template v-else-if="col.name === 'updated-at'">
                          {{ formatDate(props.row.updatedAt) }}
                        </template>

                        <div
                          v-else-if="col.name === 'agent'"
                          class="flex no-wrap items-center"
                        >
                          <q-icon
                            :color="props.row.agent.active ? 'green' : 'red'"
                            :name="
                              props.row.agent.active
                                ? 'mdi-check-circle-outline'
                                : 'mdi-close-circle-outline'
                            "
                            size="xs"
                            class="q-mr-xs"
                          />

                          <q-badge
                            :style="{
                              backgroundColor: hashStringToColor(
                                `${props.row.agent.username}_${props.row.agent.id}`
                              ),
                            }"
                          >
                            {{ props.row.agent.username }}
                          </q-badge>
                        </div>

                        <q-badge
                          v-else-if="col.name === 'author'"
                          :style="{
                            backgroundColor: hashStringToColor(
                              `${props.row.author.username}_${props.row.author.id}`
                            ),
                          }"
                        >
                          {{ props.row.author.username }}
                        </q-badge>

                        <q-badge
                          v-else-if="col.name === 'reason'"
                          :style="{
                            backgroundColor: hashStringToColor(
                              props.row.reason
                            ),
                          }"
                        >
                          {{ props.row.reason }}
                        </q-badge>

                        <span
                          v-else-if="col.name === 'input-amount'"
                          class="text-no-wrap"
                        >
                          {{
                            formatMoney({
                              value: props.row.inputAmount,
                              showSymbol: false,
                            })
                          }}

                          {{ props.row.inputCurrency.code }}
                        </span>

                        <span
                          v-else-if="col.name === 'agent-amount'"
                          class="text-no-wrap"
                        >
                          {{
                            formatMoney({
                              value: props.row.agentAmount,
                              showSymbol: false,
                            })
                          }}
                          {{ props.row.agentCurrency.code }}
                        </span>

                        <span
                          v-else-if="col.name === 'agent-fee'"
                          class="text-no-wrap"
                        >
                          {{
                            formatMoney({
                              value: props.row.agentFee,
                              showSymbol: false,
                            })
                          }}
                        </span>

                        <template v-else>
                          {{ col.value }}
                        </template>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-card>
            </div>
          </template>

          <template #top>
            <q-space />
            <q-btn
              :to="{
                name: 'CreateBalanceChange',
              }"
              unelevated
              icon="mdi-wallet-plus"
              color="primary"
              label="Create Balance Change"
            />
          </template>

          <template #body-cell-created-at="props">
            <q-td :props="props">
              {{ formatDate(props.row.createdAt) }}
            </q-td>
          </template>

          <template #body-cell-updated-at="props">
            <q-td :props="props">
              {{ formatDate(props.row.updatedAt) }}
            </q-td>
          </template>

          <template #body-cell-agent="props">
            <q-td :props="props">
              <div class="flex no-wrap items-center">
                <q-icon
                  :color="props.row.agent.active ? 'green' : 'red'"
                  :name="
                    props.row.agent.active
                      ? 'mdi-check-circle-outline'
                      : 'mdi-close-circle-outline'
                  "
                  size="xs"
                  class="q-mr-xs"
                />

                <q-badge
                  :style="{
                    backgroundColor: hashStringToColor(
                      `${props.row.agent.username}_${props.row.agent.id}`
                    ),
                  }"
                >
                  {{ props.row.agent.username }}
                </q-badge>
              </div>
            </q-td>
          </template>

          <template #body-cell-author="props">
            <q-td :props="props">
              <q-badge
                :style="{
                  backgroundColor: hashStringToColor(
                    `${props.row.author.username}_${props.row.author.id}`
                  ),
                }"
              >
                {{ props.row.author.username }}
              </q-badge>
            </q-td>
          </template>

          <template #body-cell-reason="props">
            <q-td :props="props">
              <q-badge
                :style="{
                  backgroundColor: hashStringToColor(props.row.reason),
                }"
              >
                {{ props.row.reason }}
              </q-badge>
            </q-td>
          </template>

          <template #body-cell-input-amount="props">
            <q-td :props="props">
              <span class="text-no-wrap">
                {{
                  formatMoney({
                    value: props.row.inputAmount,
                    showSymbol: false,
                  })
                }}
                {{ props.row.inputCurrency.code }}
              </span>
            </q-td>
          </template>

          <template #body-cell-agent-amount="props">
            <q-td :props="props">
              <span class="text-no-wrap">
                {{
                  formatMoney({
                    value: props.row.agentAmount,
                    showSymbol: false,
                  })
                }}
                {{ props.row.agentCurrency.code }}
              </span>
            </q-td>
          </template>

          <template #body-cell-agent-fee="props">
            <q-td :props="props">
              <span class="text-no-wrap">
                {{
                  formatMoney({
                    value: props.row.agentFee,
                    showSymbol: false,
                  })
                }}
              </span>
            </q-td>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </DViewCard>
</template>

<script>
import {
  balanceChangeOperationTypeDictionary,
  balanceChangeReasonCode,
  balanceChangeReasonDictionary,
} from '../balance-change/config';
import { tableColumns } from './config';
import { DAutocomplete } from '@/features/autocomplete';
import { DDatePicker } from '@/features/date-picker';
import DViewCard from '@/layouts/view-card';
import {
  balanceChangeController,
  currencyController,
  usersController,
} from '@/shared/api';
import { ROLES } from '@/shared/constants';
import {
  authUtils,
  formatMoney,
  hashStringToColor,
  isEmpty,
  nestedFreeze,
} from '@/shared/utils';

export default {
  components: { DViewCard, DAutocomplete, DDatePicker },
  data() {
    return {
      agents: {
        loading: false,
        list: [],
      },
      currency: {
        loading: false,
        list: [],
      },
      balanceChangesHistory: {
        list: [],
        loading: false,
        isValidFilters: true,
        filter: {
          createdAt: null,
          agentId: null,
          agentCurrency: null,
          operationType: null,
          reason: null,
          merchantTransactionId: null,
        },
      },
    };
  },
  computed: {
    tableColumns() {
      return tableColumns;
    },
    fieldRules() {
      return {
        required: (value) => !isEmpty(value) || 'Please type value',
        operationType: (value) => {
          const requiredRuleResult = [
            balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
            balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
          ].includes(this.balanceChangesHistory.filter.reason)
            ? this.fieldRules.required(value)
            : true;

          return requiredRuleResult;
        },
        merchantTransactionId: (value) => {
          const requiredRuleResult = [
            balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
            balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
          ].includes(this.balanceChangesHistory.filter.reason)
            ? this.fieldRules.required(value)
            : true;

          return requiredRuleResult;
        },
      };
    },
    balanceChangeReasonDictionary() {
      return balanceChangeReasonDictionary;
    },
    balanceChangeOperationTypeDictionary() {
      return balanceChangeOperationTypeDictionary;
    },
    accessFilterAgent() {
      return authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR]);
    },
    isXsScreen() {
      return this.$q.screen.xs;
    },
  },
  methods: {
    hashStringToColor(value) {
      return hashStringToColor(value);
    },
    formatDate(value) {
      if (!value) {
        return '';
      }

      return new Date(value).toLocaleString('en-EN', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    formatMoney({ value, currency, showSymbol }) {
      return formatMoney({
        value,
        currency,
        showSymbol,
      });
    },
    async fetchCurrencies() {
      if (!this.accessFilterAgent) {
        return;
      }

      this.currency.loading = true;

      try {
        const { data } = await currencyController(
          authUtils.getRoleByHierarchy()
        ).getCurrency();

        this.currency.list = data.map((currency) =>
          Object.freeze({
            ...currency,
            value: currency.id,
            label: currency.name,
          })
        );
      } finally {
        this.currency.loading = false;
      }
    },
    async fetchAgents() {
      if (!this.accessFilterAgent) {
        return;
      }

      this.agents.loading = true;

      try {
        const { data } = await usersController(
          authUtils.getRoleByHierarchy()
        ).agentsForBalance();

        this.agents.list = data.map((agent) =>
          Object.freeze({
            ...agent,
            value: agent.id,
            label: `${agent.username} (ID: ${agent.id})`,
          })
        );
      } finally {
        this.agents.loading = false;
      }
    },
    async fetchBalanceChanges() {
      const isValidatedFilters = this.validateFilters();

      if (!isValidatedFilters) {
        return;
      }

      this.balanceChangesHistory.loading = true;

      const { merchantTransactionId, ...filter } =
        this.balanceChangesHistory.filter;

      if (!isEmpty(merchantTransactionId)) {
        filter.merchantTransactionId = merchantTransactionId;
      }

      try {
        const data = await balanceChangeController(
          authUtils.getRoleByHierarchy()
        ).findBalanceChanges({
          filter,
          sort: {},
          limit: {
            lastId: 0,
            maxResults: 1000,
            descending: true,
          },
        });

        this.balanceChangesHistory.list = nestedFreeze(data);
      } finally {
        this.balanceChangesHistory.loading = false;
      }
    },
    validateFilters() {
      return [
        this.$refs.agent,
        this.$refs.currency,
        this.$refs.reason,
        this.$refs.merchantTransactionId,
        this.$refs.operationType,
      ]
        .filter((field) => field?.validate)
        .every((field) => field.validate() === true);
    },
    initValidateFilters() {
      this.$watch(
        () => {
          return [
            this.balanceChangesHistory.filter.agentId,
            this.balanceChangesHistory.filter.agentCurrency,
            this.balanceChangesHistory.filter.reason,
            this.balanceChangesHistory.filter.merchantTransactionId,
            this.balanceChangesHistory.filter.operationType,
          ].join('-');
        },
        () => {
          this.balanceChangesHistory.isValidFilters = this.validateFilters();
        },
        { immediate: true }
      );
    },
  },
  mounted() {
    this.initValidateFilters();
    this.fetchAgents();
    this.fetchCurrencies();
    this.fetchBalanceChanges();
  },
};
</script>

<style lang="scss">
.history-of-balance-changes {
  &__table:not(.q-table--grid) {
    height: calc(100vh - 320px);

    &.history-of-balance-changes__table {
      &_with_agent-filter {
        height: calc(100vh - 390px);
      }
    }

    thead tr th {
      position: sticky;
      z-index: 1;
    }

    thead tr:first-child th {
      top: 0;
    }

    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
      background-color: #fff;
    }

    &.q-table--loading thead tr:last-child th {
      top: 48px;
    }

    tbody {
      scroll-margin-top: 48px;
    }
  }
}
</style>
